import React from 'react';
import { Row, Col, Container, Card } from 'react-bootstrap';

function FormContainer({ children }) {
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '80vh', // Ensures the container takes the full height of the viewport
  };

  const formStyle = {
    width: '93%',
    maxWidth: '600px', // Optional: limit the max width for better design
    backgroundColor: '#FAF9F6',
    borderRadius: '5px',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.5)',
    padding: '20px', // Optional: add padding for better aesthetics
  };

  return (
    <Container style={containerStyle}>
      <Card style={formStyle}>
        <Row className="justify-content-md-center">
          <Col xs={12}>
            {children}
          </Col>
        </Row>
      </Card>
    </Container>
  );
}

export default FormContainer;
