import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Button } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';

import { userLoginActions } from '../actions/userActions';
import FormContainer from '../components/FormContainer';
import Message from '../components/Message'
import Loader from '../components/Loader'

function LoginScreen() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const location = useLocation()
  const redirect = location.search ? location.search.split('=')[1] : '/'
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userLogin = useSelector(state => state.userLogin)
  const { loading, userInfo, error, success } = userLogin

  useEffect(() => {
    if (userInfo) {
      if (success) {
        toast.success("Login Successful")
        navigate(redirect)
      }

      if (error) {
        console.log(error)
        toast.error("Error logging in")
      }
    }
  }, [userInfo, navigate, redirect, success])

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(userLoginActions(email, password))
  };

  return (
    <FormContainer>
      {error && <Message variant='danger'>{error}</Message>}
      {loading && <Loader />}
      <div><Toaster /></div>
      <Form onSubmit={submitHandler}>
        <h3>Log In.</h3>
        <Form.Group controlId='email'>
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type='email'
            value={email}
            placeholder='Enter email'
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='password' className='mt-2'>
          <Form.Label>Password</Form.Label>
          <Form.Control
            type='password'
            value={password}
            placeholder='Enter password'
            onChange={(e) => setPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Button type='submit' className='btn-custom rounded mt-3'>Log In</Button>

        <Row className='mt-2'>
          <Col>
            New to Distrikt Arts? <Link to={redirect ? `/register?redirect=${redirect}` : '/register'}>Sign up here.</Link>
          </Col>
          <br />
          <Col>
            Forgot password? Click <Link to='/send-reset-email'>this link</Link> to reset your password.
          </Col>
        </Row>

      </Form>
    </FormContainer>
  );
}

export default LoginScreen;
