import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import { Row, Col, ListGroup, Card } from 'react-bootstrap'
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js'
import toast, { Toaster } from 'react-hot-toast'

import { orderDetailsActions, orderPayActions } from '../actions/orderActions'
import { ORDER_PAY_RESET } from "../constants/orderConstants"
import Loader from '../components/Loader'
import Message from '../components/Message'

function OrderScreen() {
    const { id } = useParams()
    const dispatch = useDispatch()

    const orderDetails = useSelector(state => state.orderDetails)
    const { order, error, loading } = orderDetails

    const orderPay = useSelector(state => state.orderPayment)
    const { loading: loadingPay, success: successPay } = orderPay

    useEffect(() => {
        if (!order || successPay || order.id !== Number(id)) {
            dispatch(orderDetailsActions(id))
        }
    }, [dispatch, id, order, successPay])

    if (!loading && !error && order) {
        order.itemsPrice = order.orderItems.reduce((acc, item) => acc + item.qty * item.price, 0).toFixed(2)
    }

    const successPaymentHandler = (paymentResult) => {
        dispatch(orderPayActions(id, paymentResult))
        dispatch({ type: ORDER_PAY_RESET })
        dispatch(orderDetailsActions(id))
        toast.success("Order has been paid.")
    }

    return loading ? (
        <Loader />
    ) : error ? (
        <Message variant="danger">{error}</Message>
    ) : (
        <div>
            <div>
                <Toaster
                    toastOptions={{
                        className: '',
                        duration: 5000,
                        style: {
                            background: '#363636',
                            color: '#fff',
                        },
                        success: {
                            duration: 3000,
                            theme: {
                                primary: 'green',
                                secondary: 'black',
                            },
                        },
                    }}
                />
            </div>
            <Link to='/' className='btn btn-light my-2'>Go Back To Home</Link>
            <Row>
                <Col md={7}>
                    <ListGroup variant='flush'>
                        <ListGroup.Item>
                            <h3>Shipping Information:</h3>
                            <strong>Recipient's Name: {order.user.name}</strong>
                            <br />
                            <strong>Recipient's Email: <Link to={`mailto:${order.user.email}`}>{order.user.email}</Link></strong>
                            <br />
                            <strong>{order.shippingAddress.address}, {order.shippingAddress.city}, {order.shippingAddress.postalCode} {order.shippingAddress.country}</strong>
                            {order.isDispatched ? <Message variant='success'>Order dispatched at: {order.dispatchedAt.substring(0, 10)}</Message> : <Message variant='warning'>Order Not Dispatched</Message>}
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <h3>Payment Information:</h3>
                            <strong>Payment Method: {order.paymentMethod}</strong>
                            {order.isPaid ? <Message variant='success'>Order paid at: {order.paidAt.substring(0, 10)}.</Message> : <Message variant='warning'>Order Not Paid</Message>}
                        </ListGroup.Item>
                    </ListGroup>
                </Col>

                <Col md={5}>
                    <Card className='mt-2 rounded'>
                        <ListGroup>
                            <ListGroup.Item>
                                <h3>Order Summary:</h3>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                Item Price: $ {order.itemsPrice}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                Shipping Cost: $ {order.shippingPrice}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                Tax: $ {order.taxPrice}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                Total Order Price: $ {order.totalPrice}
                            </ListGroup.Item>
                            {!order.isPaid && (
                                <ListGroup.Item>
                                    {loadingPay && <Loader />}
                                    <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID, currency: "USD" }}>
                                        <PayPalButtons
                                            style={{ layout: "vertical" }}
                                            createOrder={(data, actions) => {
                                                return actions.order.create({
                                                    purchase_units: [
                                                        {
                                                            amount: {
                                                                value: order.totalPrice,
                                                            },
                                                        },
                                                    ],
                                                });
                                            }}
                                            onApprove={(data, actions) => {
                                                return actions.order.capture().then(successPaymentHandler);
                                            }}
                                        />
                                    </PayPalScriptProvider>
                                </ListGroup.Item>
                            )}
                        </ListGroup>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default OrderScreen
