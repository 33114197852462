import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Button, Table } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap'

import { userDetailsActions, updateActions } from '../actions/userActions';
import { USER_UPDATE_RESET } from '../constants/userConstants';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { userOrdersActions } from '../actions/orderActions';

function ProfileScreen() {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = useSelector(state => state.userDetails);
  const { loading, user, error } = userDetails;

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const userUpdate = useSelector(state => state.userUpdate)
  const { success } = userUpdate

  const userOrders = useSelector(state => state.userOrders)
  const { loading: loadingOrders, orders, error: errorOrders } = userOrders

  useEffect(() => {
    if (!userInfo) {
      navigate('/login')
    } else {
      if (!user || !user.name || success) {
        dispatch({ type: USER_UPDATE_RESET })
        dispatch(userDetailsActions('profile'))
        dispatch(userOrdersActions())
      } else {
        setName(user.name)
        setEmail(user.email)
      }
    }
  }, [dispatch, navigate, userInfo, user, success])

  const submitHandler = (e) => {
    e.preventDefault()
    setSuccessMessage('Your profile has been updated successfully!')
    dispatch(updateActions(name, email))
  }

  return (
    <div>
      <Row>
        {/* User Info */}
        <Col md={4}>
          <h3>User Information.</h3>
          {loading && <Loader />}
          {error && <Message variant='danger'>{error}</Message>}
          {message && <Message variant='danger'>{message}</Message>}
          {successMessage && <Message variant='info'>{successMessage}</Message>}
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='name'>
              <Form.Label>Name:</Form.Label>
              <Form.Control
                required
                type='name'
                value={name}
                placeholder='Enter your name'
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='email' className='mt-2'>
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                required
                type='email'
                value={email}
                placeholder='Enter email'
                onChange={(e) => setEmail(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group className='mt-3'>
              <Form.Text className='text-muted'><i class="fa-solid fa-lightbulb"></i> To change your password, click <Link to='/send-reset-email'>this link</Link> to change your password.</Form.Text>
            </Form.Group>
            <Button type='submit' className='btn-custom rounded mt-2'>Update Account</Button>
          </Form>
        </Col>

        {/* Order Info */}
        <Col md={8}>
          <h3>Your Orders.</h3>
          {loadingOrders ? (<Loader />) : errorOrders ? (<Message variant='danger'>{errorOrders}</Message>) : (
            <Table striped responsive className='table-sm'>
              <thead>
                <th>ID</th>
                <th>Order Date</th>
                <th>Total Price</th>
                <th>Paid At</th>
                <th>Delivery Status</th>
                <th>Payment Method</th>
                <th></th>
              </thead>
              <tbody>
                {orders.map(order => (
                  <tr>
                    <td>{order.id}</td>
                    <td>{order.createdAt.substring(0, 10)}</td>
                    <td>${order.totalPrice}</td>
                    <td>{order.isPaid ? order.paidAt.substring(0, 10) : <i class="fa-solid fa-circle-xmark"></i>}</td>
                    <td>{order.isDelivered ? order.deliveredAt.substring(0, 10) : <i className="fa-solid fa-circle-xmark" style={{ color: 'red' }}></i>}</td>
                    <td>{order.paymentMethod}</td>
                    <td>
                      <LinkContainer to={`/order/${order.id}`}>
                        <Button className='btn-sm'>Details</Button>
                      </LinkContainer>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
    </div>
  )
}

export default ProfileScreen