import axios from 'axios'
import { CART_ADD_ITEM, CART_REMOVE_ITEM, CART_LOADING, SAVE_SHIPPING_ADDRESS, SAVE_PAYMENT_METHOD } from "../constants/cartConstants";

export const addToCart = (id, qty, size) => async (dispatch, getState) => {
    dispatch({
        type: CART_LOADING
    })

    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/products/${id}`)

    dispatch({
        type: CART_ADD_ITEM,
        payload: {
            product: data.id,
            name: data.name,
            price: data.price,
            image: data.image,
            size: size,
            inStock: data.inStock,
            qty
        }
    })

    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const removeFromCart = (id) => (dispatch, getState) => {
    dispatch({
        type: CART_REMOVE_ITEM,
        payload: id
    })

    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const saveShippingAddress = (data) => (dispatch) => {
    dispatch({
        type: SAVE_SHIPPING_ADDRESS,
        payload: data
    })

    localStorage.setItem('shippingAddress', JSON.stringify(data))
}

export const savePaymentMethod = (data) => (dispatch) => {
    dispatch({
        type: SAVE_PAYMENT_METHOD,
        payload: data
    })

    localStorage.setItem('paymentMethod', JSON.stringify(data))
}