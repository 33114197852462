import React, { useEffect } from 'react';
import { Row, Col, Card, ListGroup, Button, Form, Image } from 'react-bootstrap';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '../components/Loader';
import Message from '../components/Message';
import { addToCart, removeFromCart } from '../actions/cartActions';

function CartScreen() {
  const { id: productId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const qty = location.search ? Number(location.search.split('=')[1]) : 1;
  const cart = useSelector(state => state.cart);
  const { cartItems, loading } = cart;

  useEffect(() => {
    if (productId) {
      dispatch(addToCart(productId, qty));
    }
  }, [dispatch, productId, qty]);

  const proceedToCheckout = () => {
    navigate('/checkout');
  };

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
  };

  return (
    <div>
      <h2 className='text-center'>Your Cart</h2>
      <Link to='/' className='btn btn-light my-2'>Go Back</Link>
      {loading ? (
        <Loader />
      ) : (
        <Row>
          <Col md={8}>
            <Row>
              {cartItems.length === 0 ? (
                <Message variant='info'>
                  Your Cart is empty <Link to='/' className='px-2'>Go Back</Link>
                </Message>
              ) : (
                <ListGroup variant='flush'>
                  {cartItems.map(item => (
                    <ListGroup.Item key={item.product}>
                      <Row>
                        <Col md={2}>
                          <Image src={`https://res.cloudinary.com/depbmpoam/${item.image}`} alt={item.name} style={{ height: "100px", width: "100px" }} fluid rounded />
                        </Col>
                        <Col md={2} className='mt-2'>
                          <Link to={`/products/${item.product}`}>{item.name}</Link>
                        </Col>
                        <Col md={1} className='mt-2'>
                          $ {item.price}
                        </Col>
                        
                        <Col md={1} className='mt-2'>
                          Size:{item.size}
                        </Col>

                        <Col md={3} className='mt-2'>
                          <Form.Control
                            as='select'
                            value={item.qty}
                            onChange={(e) => dispatch(addToCart(item.product, Number(e.target.value)))}
                          >
                            {[...Array(item.inStock).keys()].map((x) => (
                              <option key={x + 1} value={x + 1}>
                                {x + 1}
                              </option>
                            ))}
                          </Form.Control>
                        </Col>
                        <Col md={1} className='mt-2 mb-1'>
                          <Button
                            type='button'
                            variant='light'
                            onClick={() => removeFromCartHandler(item.product)}
                          >
                            <i className='fas fa-trash'></i>
                          </Button>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </Row>
          </Col>

          <Col md={4}>
            <Card className='mt-2 rounded'>
              <ListGroup variant='flush'>
                <ListGroup.Item>
                  <h2>Cart Summary</h2>
                </ListGroup.Item>
                <ListGroup.Item>
                  <p className='mt-2'>
                    Items in cart: ({cartItems.reduce((acc, item) => acc + item.qty, 0)}) items
                  </p>
                  Price: $ {cartItems.reduce((acc, item) => acc + item.qty * item.price, 0).toFixed(2)}
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button type='button' className='btn-block' onClick={proceedToCheckout} disabled={cartItems.length === 0}>
                    Proceed To Checkout
                  </Button>
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default CartScreen;