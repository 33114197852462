import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Row, Col, ListGroup, Card, Button, Image, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast'


import { productDetailsActions } from '../actions/productActions';
import { addToCart } from '../actions/cartActions';
import Rating from '../components/Rating';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Review from '../components/Review'

function ProductScreen() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const productDetails = useSelector(state => state.productDetails);
  const { error, loading, product } = productDetails;

  const [qty, setQty] = useState(1);
  const [size, setSize] = useState('')

  const rating = product.reviews.length > 0 ? product.reviews.reduce((acc, review) => acc + parseFloat(review.rating), 0) / product.reviews.length : 0

  useEffect(() => {
    dispatch(productDetailsActions(id));
  }, [dispatch, id]);

  const addToCartHandler = () => {
    if (!size) {
      toast.error("Please select a size")
    }
    const quantity = Number(qty)
    dispatch(addToCart(id, quantity, size))
    toast.success(`${product.name}, (Size - ${size}) has been added to cart!`)
  };

  return (
    <div>
      <div><Toaster /></div>
      <Link to='/' className='btn btn-light my-2'>Go Back</Link>
      {loading ? <Loader />
        : error ? <Message variant='danger'>{error}</Message>
          : (
            <>
              <Row>
                {/* First Column, Product Image */}
                <Col
                  sm={4}
                  md={5}
                  className='d-flex justify-content-center align-items-center'
                  style={{ height: '330px' }} // Ensure the column has a fixed height
                >
                  <Image
                    src={`https://res.cloudinary.com/depbmpoam/${product.image}`}
                    alt={product.name}
                    style={{ height: '100%', width: 'auto' }} // Ensure image scales correctly
                  />
                </Col>

                {/* Second Column, Product Info */}
                <Col sm={4} md={4}>
                  <ListGroup variant='flush'>
                    <ListGroup.Item>
                      <h3>{product.name}</h3>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Rating value={rating} text={`${product.reviews.length} ratings`} color={'#FFD700'} />
                    </ListGroup.Item>

                    <ListGroup.Item>
                      Price: $ {product.price}
                    </ListGroup.Item>

                    <ListGroup.Item>
                      Description: <br /> {product.description}
                    </ListGroup.Item>
                  </ListGroup>
                </Col>

                {/* Third Column, Product Summary and Pricing */}
                <Col sm={4} md={3}>
                  <Card className='rounded mt-2'>
                    <ListGroup variant='flush'>
                      <ListGroup.Item>
                        <Row>
                          <Col>Price:</Col>
                          <Col><strong>${product.price}</strong></Col>
                        </Row>
                      </ListGroup.Item>

                      <ListGroup.Item>
                        <Row>
                          <Col>Product Status:</Col>
                          <Col>{product.in_stock > 0 ? <strong>In Stock</strong> : <strong>Out of Stock</strong>}</Col>
                        </Row>
                      </ListGroup.Item>

                      <ListGroup.Item>
                        <Row>
                          <Col>Size:</Col>
                          <Form.Control as='select' placeholder='Size...' value={size} onChange={(e) => setSize(e.target.value)}>
                            <option value=''>Select...</option>
                            <option value='Small'>Small - S</option>
                            <option value='Medium'>Medium - M</option>
                            <option value='Large'>Large - L</option>
                            <option value='Extra Large'>Extra Large - XL</option>
                          </Form.Control>
                        </Row>
                      </ListGroup.Item>
                      {product.in_stock > 0 &&
                        <ListGroup.Item>
                          <Row>
                            <Col>Quantity: </Col>
                            <Col xs='auto' className='my-1'>
                              <Form.Control as='select' value={qty} onChange={(e) => setQty(e.target.value)}>

                                {/* This creates a new array, and maps through it, [0,1,2,3,4,5] */}
                                {[...Array(product.in_stock).keys()].map((x) => (
                                  <option key={x + 1} value={x + 1}>
                                    {x + 1}
                                  </option>
                                ))}

                              </Form.Control>
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      }

                      <ListGroup.Item>
                        <Button className='btn-block btn-dark rounded mt -2' type='button' disabled={product.inStock === 0} onClick={addToCartHandler}>Add To Cart</Button>
                      </ListGroup.Item>

                    </ListGroup>
                  </Card>
                </Col>
              </Row>
              <Row className='mt-2'>
                <Review />
              </Row>
            </>
          )
      }
    </div>
  );
}

export default ProductScreen;