import { CART_ADD_ITEM, CART_REMOVE_ITEM, CART_LOADING, SAVE_SHIPPING_ADDRESS, SAVE_PAYMENT_METHOD, CART_CLEAR_ITEMS } from "../constants/cartConstants";

const initialState = {
    cartItems : [],
    shippingAddress: {},
    loading: false
}

export const cartReducer = (state = initialState, action) => {
    switch(action.type){

        case CART_LOADING:
            return {
                ...state,
                loading: true,
            }

        case CART_ADD_ITEM:
            const item = action.payload
            const existItem = state.cartItems.find(x => x.product === item.product) // .product === .id

            if(existItem) {
                return {
                    ...state,
                    loading:false,
                    cartItems: state.cartItems.map(x => x.product === existItem.product ? item : x)
                }
            } else {
                return {
                    ...state,
                    loading: false,
                    cartItems: [...state.cartItems, item]
                }
            }

        case CART_REMOVE_ITEM:
            return {
                ...state,
                cartItems: state.cartItems.filter(x => x.product !== action.payload)
            }

        case SAVE_SHIPPING_ADDRESS:
            return {
                ...state,
                shippingAddress: action.payload
            }

        case SAVE_PAYMENT_METHOD:
            return {
                ...state,
                paymentMethod: action.payload
            }

        case CART_CLEAR_ITEMS:
            return {
                ...state,
                cartItems: []
            }

        default:
            return state
    }
}