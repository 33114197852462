import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { ListGroup } from 'react-bootstrap'

import { productReviewsActions } from '../actions/productActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import Rating from '../components/Rating'

function ReviewsScreen() {

    const { id } = useParams()

    const dispatch = useDispatch()
    const productReviews = useSelector(state => state.productReviews)
    const { loading, reviews, error } = productReviews

    useEffect(() => {
        dispatch(productReviewsActions(id))
    }, [dispatch, id])

    return (
        loading ? <Loader /> : error ? <Message variant='danger'>{error}</Message> : (
            <div>
                <Link to={`/products/${id}`} className='btn btn-light my-2'>Go Back</Link>
                <h3 className='text-center'>Product Reviews</h3>
                <ListGroup variant='flush'>
                    {reviews.map(review => (
                        <ListGroup.Item key={review.id}>
                            <strong>{review.name}</strong>
                            <Rating value={review.rating} color={'#FFD700'} />
                            <p>Comment: {review.comment}</p>
                            <p>Created at: {review.createdAt.substring(0, 10)}</p>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </div>
        )
    )
}

export default ReviewsScreen
