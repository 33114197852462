import React, { useState, useEffect } from 'react'
import { Container, Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import toast, { Toaster } from 'react-hot-toast'

import Loader from '../components/Loader'
import Message from '../components/Message'
import { sendContactEmailAction } from '../actions/userActions'

function ContactScreen() {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    const dispatch = useDispatch()
    const sendContactEmail = useSelector(state => state.sendContactEmail)
    const { loading, success, error } = sendContactEmail

    const sendReply = (e) => {
        e.preventDefault();
        dispatch(sendContactEmailAction(name, email, message))
    }

    useEffect(() => {
        if (success) {
            toast.success("Email sent successfully")
        }
        if (error) {
            toast.error("An error has occurred.")
        }
    }, [success, error])

    return (
        <>
            <div><Toaster /></div>
            <div className="contact-screen">
                <h2>Contact Us</h2>
                {loading && <Loader />}
                {error && <Message variant='danger'>{error}</Message>}
                <Container>
                    <p>
                        Here at Distrikt Arts, we appreciate your feedback as our loyal customers. Any feedback, comments, or any help is greatly appreciated by us. Use this form below to send an email to our contact desk and we will get back to you within 24 hours. If not, send an email to <strong>help@distriktarts.net</strong> or call <strong>+ 1 234-567-8902</strong> for a faster response.
                    </p>
                    <Row>
                        <Col md={3}></Col>
                        <Col md={6}>
                            <Form onSubmit={sendReply} className="contact-form">
                                <Form.Group className="mb-3" controlId="formBasicName">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={name}
                                        placeholder="Enter name"
                                        className="rounded"
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control
                                        type="email"
                                        value={email}
                                        placeholder="Enter email"
                                        className="rounded"
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <Form.Text className="text-muted">
                                        We'll never share your email with anyone else.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicMessage">
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        value={message}
                                        rows={5}
                                        placeholder="Message Here"
                                        className="rounded"
                                        onChange={(e) => setMessage(e.target.value)}
                                    />
                                </Form.Group>

                                <Button type="submit" className="rounded">
                                    Submit Message
                                </Button>
                            </Form>
                        </Col>
                        <Col md={3}></Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default ContactScreen
