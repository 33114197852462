import React, { useState, useEffect } from 'react';
import { Col, Row, Form, Button, ListGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import CountrySelect from 'react-bootstrap-country-select';
import CheckoutSteps from '../components/CheckoutSteps';
import { saveShippingAddress } from '../actions/cartActions';

function CheckoutScreen() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const cart = useSelector(state => state.cart);
    const { shippingAddress, cartItems } = cart;

    const [name, setName] = useState(shippingAddress?.name || '');
    const [address, setAddress] = useState(shippingAddress?.address || '');
    const [city, setCity] = useState(shippingAddress?.city || '');
    const [country, setCountry] = useState(shippingAddress?.country || '');
    const [postalCode, setPostalCode] = useState(shippingAddress?.postalCode || '');

    useEffect(() => {
        if (!shippingAddress?.address) {
            navigate('/checkout');
        }
    }, [shippingAddress, navigate]);

    const submitHandler = (e) => {
        e.preventDefault();
        const countryName = country.name ? country.name : country;
        dispatch(saveShippingAddress({ name, address, city, postalCode, country: countryName }));
        navigate('/payment');
    };

    return (
        <Row>
            <CheckoutSteps step1 step2 />

            <Col md={5}>
                <h2 className='text-center'>Cart Summary</h2>
                <ListGroup.Item>
                    <p className='mt-2'>
                        Items in cart: ({cartItems.reduce((acc, item) => acc + item.qty, 0)}) items
                    </p>
                    Price: $ {cartItems.reduce((acc, item) => acc + item.qty * item.price, 0).toFixed(2)}
                </ListGroup.Item>
            </Col>

            <Col md={7} className='mt-4'>
                <h2 className='text-center'>Shipping Information</h2>
                <Form onSubmit={submitHandler}>
                    <Form.Group>
                        <Form.Label>Name:</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mt-2">
                        <Form.Label>Address:</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Enter Address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mt-2">
                        <Form.Label>State:</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Enter your State"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mt-2">
                        <Form.Label>Country:</Form.Label>
                        <CountrySelect
                            value={country}
                            onChange={setCountry}
                            matchNameFromStart={false}
                            matchAbbreviations
                        />
                    </Form.Group>

                    <Form.Group className="mt-2">
                        <Form.Label>Postal Code:</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Enter Postal Code"
                            value={postalCode}
                            onChange={(e) => setPostalCode(e.target.value)}
                        />
                    </Form.Group>

                    <Button type="submit" className="mt-3">
                        Continue To Payments
                    </Button>
                </Form>
            </Col>
        </Row>
    );
}

export default CheckoutScreen;
