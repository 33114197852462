import { legacy_createStore, applyMiddleware, combineReducers } from 'redux';
import { thunk } from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';

import { productListReducer, productDetailsReducer, productReviewReducer, productTopRatedReducer, productReviewsReducer } from './reducers/productReducers';
import { cartReducer } from './reducers/cartReducers';
import { userLoginReducer, userRegisterReducer, userDetailsReducer, userUpdateReducer, userVerifyReducer, resetPasswordReducer, sendResetEmailReducer, verifyEmailReducer, sendContactEmailReducer, resendOtpReducer } from './reducers/userReducers';
import { orderCreateReducer, orderDetailsReducer, orderPaymentReducer, userOrdersReducer } from './reducers/orderReducers';


const reducer = combineReducers({
    cart: cartReducer,

    productList: productListReducer,
    productDetails: productDetailsReducer,
    productReview: productReviewReducer,
    productTopRated: productTopRatedReducer,
    productReviews: productReviewsReducer,

    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,
    userDetails: userDetailsReducer,
    userUpdate: userUpdateReducer,
    userVerify: userVerifyReducer,
    sendResetEmail: sendResetEmailReducer,
    verifyEmail: verifyEmailReducer,
    resetPassword: resetPasswordReducer,
    sendContactEmail: sendContactEmailReducer,
    resendOtp: resendOtpReducer,

    userOrders: userOrdersReducer,
    orderCreate: orderCreateReducer,
    orderDetails: orderDetailsReducer,
    orderPayment: orderPaymentReducer,

});

const userInfoFromStorage = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : null
const cartItemsFromStorage = localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [];
const shippingAddressFromStorage = localStorage.getItem('shippingAddress') ? JSON.parse(localStorage.getItem('shippingAddress')) : {};

const initialState = {
    cart: {
        cartItems: cartItemsFromStorage,
        shippingAddress: shippingAddressFromStorage
    },
    userLogin: {
        userInfo: userInfoFromStorage
    }
};

const middleWare = [thunk];

const store = legacy_createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleWare)));

export default store;
