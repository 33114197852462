import axios from "axios";
import { PRODUCT_LIST_FAIL, PRODUCT_LIST_REQUEST, PRODUCT_LIST_SUCCESS, PRODUCT_DETAILS_FAIL, PRODUCT_DETAILS_REQUEST, PRODUCT_DETAILS_SUCCESS, PRODUCT_REVIEW_REQUEST, PRODUCT_REVIEW_SUCCESS, PRODUCT_REVIEW_FAIL, TOP_PRODUCTS_REQUEST, TOP_PRODUCTS_SUCCESS, TOP_PRODUCTS_FAIL, GET_PRODUCT_REVIEWS_REQUEST, GET_PRODUCT_REVIEWS_SUCCESS, GET_PRODUCT_REVIEWS_FAIL } from "../constants/productConstants";

export const productListActions = (keyword = '') => async (dispatch) => {
    try {
        dispatch({
            type: PRODUCT_LIST_REQUEST
        })

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/products${keyword}`)

        dispatch({
            type: PRODUCT_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: PRODUCT_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const productDetailsActions = (id) => async (dispatch) => {
    try {
        dispatch({
            type: PRODUCT_DETAILS_REQUEST
        })

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/products/${id}`)

        dispatch({
            type: PRODUCT_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: PRODUCT_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const productReviewActions = (id, review) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PRODUCT_REVIEW_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/products/${id}/post-review/`, review, config)

        dispatch({
            type: PRODUCT_REVIEW_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: PRODUCT_REVIEW_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
        })
    }
}

export const productTopRatedActions = () => async (dispatch) => {
    try {
        dispatch({
            type: TOP_PRODUCTS_REQUEST
        })

        const config = {
            headers: {
                "Content-type": "application/json"
            }
        }

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/products/featured/`, config)

        dispatch({
            type: TOP_PRODUCTS_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: TOP_PRODUCTS_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
        })
    }
}

export const productReviewsActions = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_PRODUCT_REVIEWS_REQUEST
        })
    
        const {
            userLogin: { userInfo }
        } = getState()
    
        const config = {
            headers : {
                'Content-type': "application/json"
            }
        }
    
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/products/${id}/get-reviews/`, config)
    
        dispatch({
            type: GET_PRODUCT_REVIEWS_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: GET_PRODUCT_REVIEWS_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
        })
    }
}

