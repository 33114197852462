import React, { useState, useEffect } from 'react'
import { Form, Button } from 'react-bootstrap'
import toast, { Toaster } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useNavigate } from 'react-router-dom'
import { resetPasswordAction } from '../actions/userActions'
import Loader from '../components/Loader'


function ResetPasswordScreen() {

    const { uid, token } = useParams()

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const resetPwd = useSelector(state => state.resetPassword)
    const { loading, error, success } = resetPwd

    const validatePassword = (password) => {
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);
        const minLength = password.length >= 8;

        if (!hasUpperCase) {
            toast.error("Password must contain at least one uppercase letter.");
            return false;
        }

        if (!hasLowerCase) {
            toast.error("Password must contain at least one lowercase letter.");
            return false;
        }

        if (!hasNumber) {
            toast.error("Password must contain at least one number.");
            return false;
        }

        if (!minLength) {
            toast.error("Password must be at least 8 characters long.");
            return false;
        }

        return true;
    }


    useEffect(() => {
        toast.success("Password reset successful!")
        navigate('/login')

    }, [navigate])

    const resetPwdHandler = (e) => {
        e.preventDefault()
        if (!validatePassword(password)) {
            return
        }

        if (password !== confirmPassword) {
            toast.error('Passwords do not match.')
        }

        dispatch(resetPasswordAction({ uid, token, password }))

        if (success) {
            toast.success("Password reset successful!")
            navigate('/login')
        }

        if (error) {
            toast.error(error)
        }
    }

    return (
        <div>
            <div><Toaster /></div>
            <h4>Reset Your Password</h4>
            {loading && <Loader />}
            <Form onSubmit={resetPwdHandler}>
                <Form.Group>
                    <Form.Label>Enter New Password: </Form.Label>
                    <div className="input-group">
                        <Form.Control
                            type={'password'}
                            placeholder='Enter password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className="input-group mt-4">
                        <Form.Control
                            type={'password'}
                            placeholder='Confirm password'
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </div>
                    <Form.Text className='text-muted'>
                        Include the following in your password for strength:
                        <ul>
                            <li>A, B: uppercase letters</li>
                            <li>a, b: lowercase letters</li>
                            <li>%,$,&,: symbols</li>
                            <li>0, 9: numbers</li>
                        </ul>
                    </Form.Text>
                </Form.Group>

                <Button type='submit' className='mt-2 rounded'>Reset Password</Button>
            </Form>
        </div>
    )
}

export default ResetPasswordScreen
