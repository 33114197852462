import React from 'react'

function AboutScreen() {
  return (
    <div>
        <h2>About Us</h2>
    </div>
  )
}

export default AboutScreen