import React from 'react';
import { Navbar, Container, Nav, NavDropdown, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';

import { logout } from '../actions/userActions';
import favicon from '../assets/logo.png'
import SearchComponent from './SearchComponent';

function Header() {
  const dispatch = useDispatch();
  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin || {};

  const logoutHandler = () => {
    dispatch(logout())
  };

  return (
    <div>
      <header>
        <Navbar expand="lg" className="navbar" variant='dark' collapseOnSelect>
          <Container fluid>
            <div className="navbar-content">

              <div className="nav-left">
                <LinkContainer to='/'>
                  <Navbar.Brand>
                    <Image src={favicon} alt="Distrikt Arts" style={{ height: "100px", width: "150px" }} />
                  </Navbar.Brand>
                </LinkContainer>
              </div>

              <div className="nav-right">
                <Navbar.Toggle />
                <Navbar.Collapse>
                  <Nav navbarScroll>
                    <LinkContainer to='/'>
                      <Nav.Link><i className="fa-solid fa-house"></i> Home</Nav.Link>
                    </LinkContainer>

                    <LinkContainer to='/cart'>
                      <Nav.Link><i className="fa-solid fa-bag-shopping"></i> Cart</Nav.Link>
                    </LinkContainer>

                    {userInfo ? (
                      <NavDropdown title={userInfo.name} id='username'>
                        <LinkContainer to='/profile'>
                          <NavDropdown.Item><i className="fa-solid fa-user"></i> Profile</NavDropdown.Item>
                        </LinkContainer>

                        <NavDropdown.Item onClick={logoutHandler}><i className="fa-solid fa-arrow-right-from-bracket"></i> Logout</NavDropdown.Item>
                      </NavDropdown>
                    ) : (
                      <>
                        <LinkContainer to='/login'>
                          <Nav.Link>
                            <i className='fas fa-user'></i> Login
                          </Nav.Link>
                        </LinkContainer>

                        <LinkContainer to='/register'>
                          <Nav.Link>
                            <i className='fas fa-user'></i> Register
                          </Nav.Link>
                        </LinkContainer>
                      </>
                    )}
                  </Nav>
                  <SearchComponent />
                </Navbar.Collapse>
              </div>
            </div>
          </Container>
        </Navbar>
      </header>
    </div>
  );
}

export default Header;
