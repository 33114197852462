export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'

export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET'

export const USER_VERIFY_REQUEST = 'USER_VERIFY_REQUEST'
export const USER_VERIFY_SUCCESS = 'USER_VERIFY_SUCCESS'
export const USER_VERIFY_FAIL = 'USER_VERIFY_FAIL'

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL'

export const SEND_RESET_EMAIL_REQUEST = 'SEND_RESET_EMAIL_REQUEST'
export const SEND_RESET_EMAIL_SUCCESS = 'SEND_RESET_EMAIL_SUCCESS'
export const SEND_RESET_EMAIL_FAIL = 'SEND_RESET_EMAIL_FAIL'

export const VERIFY_EMAIL_REQUEST = 'VERIFY_EMAIL_REQUEST'
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS'
export const VERIFY_EMAIL_FAIL = 'VERIFY_EMAIL_FAIL'

export const CONTACT_EMAIL_REQUEST = 'CONTACT_EMAIL_REQUEST'
export const CONTACT_EMAIL_SUCCESS = 'CONTACT_EMAIL_SUCCESS'
export const CONTACT_EMAIL_FAIL = 'CONTACT_EMAIL_FAIL'

export const RESEND_OTP_SUCCESS = 'RESEND_OTP_SUCCESS'
export const RESEND_OTP_REQUEST = 'RESEND_OTP_REQUEST'
export const RESEND_OTP_FAIL = 'RESEND_OTP_FAIL'
