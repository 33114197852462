// Loader component, shows the page is loading correctly
import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { ClockLoader } from 'react-spinners'

function Loader({ loading }) {


    return (
        <div className="sweet-loading">
            <ClockLoader
                loading={loading}
                size={150}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </div>
    )
}


export default Loader