import React, { useState, useEffect } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import toast, { Toaster } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import { sendResetEmailAction } from '../actions/userActions'
import Loader from '../components/Loader'
import Message from '../components/Message'

function SendResetEmailScreen() {

    const [email, setEmail] = useState('')

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const sendEmail = useSelector(state => state.sendResetEmail)
    const { loading, success, error } = sendEmail

    useEffect(() => {
        if (success) {
            toast.success("Email sent successfully! Check your email for password reset.")
        }
    }, [success, navigate])

    const sendEmailHandler = (e) => {
        e.preventDefault()
        dispatch(sendResetEmailAction(email))
    }

    return (
        <div>
            <div><Toaster /></div>
            <h3>Reset Your Password</h3>
            <Form onSubmit={sendEmailHandler}>
                {loading && <Loader />}
                {error && <Message variant='danger'>{error}</Message>}
                <h5>Enter your email here to receive instructions on how to reset your password</h5>

                <Form.Group controlId='email'>
                    <Form.Label>Email: </Form.Label>
                    <Form.Control
                        type='email'
                        placeholder='Enter email...'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </Form.Group>
                <Button type='submit' className='mt-2'>Send Email</Button>
            </Form>
        </div>
    )
}

export default SendResetEmailScreen
