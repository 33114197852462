import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";

function Anklets() {
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2,
        },
    };

    useEffect(() => {
        const config = {
            headers: {
                "Content-type": "application/json",
            },
        };

        setLoading(true);
        axios
            .get(`${process.env.REACT_APP_API_URL}/products/category/anklets`, config)
            .then((response) => {
                const allProducts = response.data.flatMap((item) => item.products)
                setProducts(allProducts);
                setLoading(false);
            })
            .catch((error) => {
                toast.error("Failed to load products, try reloading page");
                console.error(error);
                setLoading(false);
            });
    }, []);

    return (
        <div className="mt-3">
            <h4>Anklets</h4>
            {loading && <div>Loading...</div>}
            <Carousel responsive={responsive}>
                {products.map((product) => (
                    <Card
                        key={product.id}
                        style={{
                            height: "auto",
                            margin: "10px",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
                            borderRadius: "10px",
                        }}
                        rounded
                    >
                        <Link to={`/products/${product.id}`}>
                            <Card.Img
                                src={`https://res.cloudinary.com/depbmpoam/${product.image}`}
                                alt={product.name}
                                className="img-fluid"
                                style={{ height: "300px", width: "300px" }}
                            />
                        </Link>
                        <Card.Body>
                            <Link to={`/products/${product.id}`}>
                                <Card.Title>
                                    <strong>{product.name}</strong>
                                </Card.Title>
                            </Link>
                            <Card.Text as="h3">$ {product.price}</Card.Text>
                        </Card.Body>
                    </Card>
                ))}
            </Carousel>
        </div>
    );
}

export default Anklets;
