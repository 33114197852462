import { USER_LOGIN_REQUEST, USER_LOGIN_FAIL, USER_LOGIN_SUCCESS, USER_LOGOUT, USER_REGISTER_FAIL, USER_REGISTER_REQUEST, USER_REGISTER_SUCCESS, USER_DETAILS_FAIL, USER_DETAILS_REQUEST, USER_DETAILS_SUCCESS, USER_UPDATE_FAIL, USER_UPDATE_REQUEST, USER_UPDATE_SUCCESS, USER_UPDATE_RESET, USER_DETAILS_RESET, USER_VERIFY_FAIL, USER_VERIFY_REQUEST, USER_VERIFY_SUCCESS, RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAIL, SEND_RESET_EMAIL_REQUEST, SEND_RESET_EMAIL_SUCCESS, SEND_RESET_EMAIL_FAIL, VERIFY_EMAIL_REQUEST, VERIFY_EMAIL_SUCCESS, VERIFY_EMAIL_FAIL, CONTACT_EMAIL_REQUEST, CONTACT_EMAIL_SUCCESS, CONTACT_EMAIL_FAIL, RESEND_OTP_SUCCESS, RESEND_OTP_REQUEST, RESEND_OTP_FAIL} from "../constants/userConstants";

export const userLoginReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return { loading: true }

        case USER_LOGIN_SUCCESS:
            return { loading: false, userInfo: action.payload, success:true }

        case USER_LOGIN_FAIL:
            return { loading: false, error: action.payload }

        case USER_LOGOUT:
            return {}

        default:
            return state
    }
}

export const userRegisterReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_REGISTER_REQUEST:
            return { loading: true }

        case USER_REGISTER_SUCCESS:
            return { loading: false, success: true, userInfo: action.payload }

        case USER_REGISTER_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const userDetailsReducer = (state = { user: {} }, action) => {
    switch(action.type) {
        case USER_DETAILS_REQUEST:
            return { ...state, loading: true }
        
        case USER_DETAILS_SUCCESS:
            return { loading: false, success: true, user: action.payload }
        
        case USER_DETAILS_FAIL:
            return { loading: false, error: action.payload }

        case USER_DETAILS_RESET:
            return { user: {} }

        default:
            return state
    }
}

export const userUpdateReducer = (state = { userInfo: {} }, action) => {
    switch(action.type) {
        case USER_UPDATE_REQUEST:
            return { loading: true }
        
        case USER_UPDATE_SUCCESS:
            return { loading: false, userInfo: action.payload }
        
        case USER_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case USER_UPDATE_RESET:
            return {}

        default:
            return state
    }
}

export const userVerifyReducer = (state = {}, action) => {
    switch(action.type) {
        case USER_VERIFY_REQUEST:
            return { loading: true }

        case USER_VERIFY_SUCCESS:
            return { loading:false, success:true, userInfo: action.payload }

        case USER_VERIFY_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const resetPasswordReducer = (state = { userInfo:{} }, action) => {
    switch (action.type) {
        case RESET_PASSWORD_REQUEST:
            return { loading: true }
        case RESET_PASSWORD_SUCCESS:
            return { loading: false, userInfo: action.payload}
        case RESET_PASSWORD_FAIL:
            return { loading:false, error: action.payload }
        default:
            return state
    }
}

export const sendResetEmailReducer = (state = {}, action) => {
    switch (action.type) {
        case SEND_RESET_EMAIL_REQUEST:
            return { loading: true };
        case SEND_RESET_EMAIL_SUCCESS:
            return { loading: false, success: true };
        case SEND_RESET_EMAIL_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const verifyEmailReducer = (state = {}, action) => {
    switch (action.type) {
        case VERIFY_EMAIL_REQUEST:
            return { loading: true };
        case VERIFY_EMAIL_SUCCESS:
            return { loading: false, success: true };
        case VERIFY_EMAIL_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const sendContactEmailReducer = (state = {}, action) => {
    switch(action.type) {
        case CONTACT_EMAIL_REQUEST:
            return { loading:true, success:false }

        case CONTACT_EMAIL_SUCCESS:
            return { loading:false, success:true }

        case CONTACT_EMAIL_FAIL:
            return { loading: false, success: false, error:action.payload}

        default:
            return state
    }
}

export const resendOtpReducer = (state = {}, action) => {
    switch(action.type) {
        case RESEND_OTP_REQUEST:
            return { loading:true, success:false }

        case RESEND_OTP_SUCCESS:
            return { loading:false, success:true }

        case RESEND_OTP_FAIL:
            return { loading: false, success: false, error:action.payload}

        default:
            return state
    }
}
