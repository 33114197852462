import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';


import CheckoutSteps from '../components/CheckoutSteps';
import FormContainer from '../components/FormContainer';
import { savePaymentMethod } from '../actions/cartActions';

function PaymentScreen() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [paymentMethod, setPaymentMethod] = useState('Paypal');

    const cart = useSelector(state => state.cart);
    const { shippingAddress } = cart;

    useEffect(() => {
        if (!shippingAddress.address) {
            navigate('/checkout');
        }
    }, [shippingAddress, navigate]);

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(savePaymentMethod(paymentMethod));
        navigate('/place-order');
    };

    return (
        <FormContainer>
            <CheckoutSteps step1 step2 step3 />
            <Form onSubmit={submitHandler}>
                <Form.Group>
                    <Form.Label>Select Payment Method:</Form.Label>
                    <Form.Check
                        type='radio'
                        label='Paypal'
                        id='Paypal'
                        name='paymentMethod'
                        value='Paypal'
                        checked={paymentMethod === 'Paypal'}
                        onChange={(e) => setPaymentMethod(e.target.value)}
                    />
                    <Form.Check
                        type='radio'
                        label='Credit or Debit Card'
                        id='Credit or Debit Card'
                        name='paymentMethod'
                        value='Credit or Debit Card'
                        checked={paymentMethod === 'Credit or Debit Card'}
                        onChange={(e) => setPaymentMethod(e.target.value)}
                    />

                </Form.Group>
                <Button type='submit' variant='primary'>
                    Continue
                </Button>
            </Form>
        </FormContainer>
    );
}

export default PaymentScreen;
