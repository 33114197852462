import React from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div className='footer mt-2 p-4'>
      <Row className='justify-content-around'>
        <Col md={4} className='footer-col'>
          <h3>distrikt arts</h3>
          <strong>Contact Us at:</strong>
          <br />
          <Link to='/contact-us' className='footer-link mt-2'> <i class="fa-solid fa-envelope"></i> support@distriktarts.net</Link>
          <br />
          <Link to='/contact-us' className='footer-link'><i class="fa-solid fa-phone"></i> +1-202-878-3831</Link>
        </Col>

        <Col md={4} className='footer-col mt-2 mb-2'>
          <strong>Subscribe to our newsletter to get updates about new products and more!</strong>
          <Form className='mt-2'>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address:</Form.Label>
              <Form.Control type="email" placeholder="Enter email" />
              <Form.Text>
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check type="checkbox" label="By checking this box, you allow us to send you newsletters on product information" />
            </Form.Group>

            <Button variant="dark" type="submit" className='btn-block rounded'>
              Subscribe
            </Button>
          </Form>
        </Col>

        <Col md={4} className='text-center footer-col mt-2'>
          <h5>Quick Links:</h5>
          <ul className='list-unstyled mt-2'>
            <Link to='/' className='footer-link'><li>Home</li></Link>
            {/*'Will work on this later when we get photos.' <Link to='/about' className='footer-link'><li>About Us</li></Link>*/}
            <Link to='/cart' className='footer-link'><li>Cart</li></Link>
            <Link to='/faq' className='footer-link'><li>FAQ</li></Link>
            <Link to='/contact-us' className='footer-link'><li>Contact Us/Help</li></Link>
          </ul>
        </Col>
      </Row>
      <footer className='text-center mt-4'>
        &copy; 2024 Distrikt Arts
      </footer>
    </div>
  )
}

export default Footer
