import axios from 'axios'
import { ADD_COUPON_FAIL, ADD_COUPON_REQUEST, ADD_COUPON_SUCCESS, ORDER_CREATE_FAIL, ORDER_CREATE_REQUEST, ORDER_CREATE_SUCCESS, ORDER_DETAILS_FAIL, ORDER_DETAILS_REQUEST, ORDER_DETAILS_SUCCESS, ORDER_PAY_REQUEST, ORDER_PAY_RESET, ORDER_PAY_SUCCESS, ORDER_PAY_FAIL, ORDER_DELIVERY_SUCCESS, USER_ORDERS_REQUEST, USER_ORDERS_SUCCESS, USER_ORDERS_FAIL } from "../constants/orderConstants";
import { CART_CLEAR_ITEMS } from '../constants/cartConstants';
import { USER_DETAILS_REQUEST } from '../constants/userConstants';


export const orderCreateActions = (order) => async (dispatch, getState) => {
    try {
        dispatch({ type: ORDER_CREATE_REQUEST });

        const {
            userLogin: { userInfo }
        } = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            }
        };

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/orders/place-order/`, order, config);

        dispatch({
            type: ORDER_CREATE_SUCCESS,
            payload: data
        });

        dispatch({
            type: CART_CLEAR_ITEMS
        })

        localStorage.removeItem("cartItems")

    } catch (error) {
        dispatch({
            type: ORDER_CREATE_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
        });
    }
};

export const validateCoupon = (coupon) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ADD_COUPON_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            }
        };

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/orders/validate-coupon`, config, coupon)

        dispatch({
            type: ADD_COUPON_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ADD_COUPON_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
        })
    }
}

export const orderDetailsActions = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ORDER_DETAILS_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            }
        };

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/orders/${id}/`, config)

        dispatch({
            type: ORDER_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ORDER_DETAILS_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
        })
    }
}

export const orderPayActions = (id, paymentResult) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ORDER_PAY_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            }
        };

        const { data } = await axios.put(`${process.env.REACT_APP_API_URL}/orders/${id}/pay`, paymentResult, config)

        dispatch({
            type: ORDER_PAY_SUCCESS,
            payload: data
        })

        dispatch({
            type: ORDER_PAY_RESET
        })

        localStorage.removeItem("paymentMethod")
        localStorage.removeItem("shippingAddress")
        localStorage.removeItem("__paypal_storage__")

    } catch (error) {
        dispatch({
            type: ORDER_PAY_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
        })
    }
}

export const userOrdersActions = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_ORDERS_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            }
        };

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/orders/my-orders`, config)

        dispatch({
            type: USER_ORDERS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: USER_ORDERS_FAIL,
            payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
        })
    }
}
