import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, Container, Row, Col } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast'

import { verifyResetOtp, resendOtpAction } from '../actions/userActions'
import Loader from '../components/Loader'
import Message from '../components/Message'

function VerifyResetOtpScreen() {
    const [otp, setOtp] = useState('')
    const [email, setEmail] = useState('')

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const verifyEmail = useSelector((state) => state.verifyEmail)
    const { loading, error, success } = verifyEmail


    useEffect(() => {
        if (success) {
            toast.success('OTP verified successfully!')
            navigate('/reset-password')
        }
        if (error) {
            toast.error(error)
        }
    }, [success, error, navigate])


    const verifyOtpHandler = (e) => {
        e.preventDefault()
        dispatch(verifyResetOtp(otp))
        toast.success('OTP verified successfully!')
    }

    const resendOtpHandler = () => {
        toast("Email has been resent successfully")
        dispatch(resendOtpAction(email))
    }

    return (
        <Container className="mt-5">
            <Toaster position="top-right" reverseOrder={false} />
            <Row className="justify-content-md-center">
                <Col xs={12} md={6}>
                    <h3 className="text-center">Verify Your OTP</h3>
                    <p className="text-center">
                        Enter the OTP sent to your email to reset your password.
                    </p>
                    {loading && <Loader />}
                    {error && <Message variant="danger">{error}</Message>}
                    <Form onSubmit={verifyOtpHandler}>
                        <Form.Group controlId="otp" className="mb-3">
                            <Form.Label>OTP Code</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter OTP"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                disabled={loading}
                            />
                        </Form.Group>
                        <Button
                            variant="primary"
                            type="submit"
                            className="w-100 mt-2"
                            disabled={loading}
                        >
                            Verify OTP
                        </Button>
                    </Form>
                    <p className="mt-3">Haven't received your OTP yet?</p>
                    <Link to="#" onClick={resendOtpHandler} className='btn btn-link btn-sm' disabled={loading}>
                        Resend OTP
                    </Link>
                </Col>
            </Row>
        </Container>
    )
}

export default VerifyResetOtpScreen
