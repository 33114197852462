import React, { useState } from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { Link, useNavigate, useLocation } from 'react-router-dom'

function SearchComponent() {

    const [keyword, setKeyword] = useState('')
    const navigate = useNavigate()
    const location = useLocation()


    const submitHandler = (e) => {
        e.preventDefault()
        if (keyword) {
            navigate(`/?keyword=${keyword}`)
        } else {
            navigate(location.pathname)
        }
    }

  return (
    <Form onSubmit={submitHandler} inline>
    <Row>
      <Col xs="auto">
        <Form.Control
          type="text"
          placeholder="Search Products"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          className="mr-sm-2"
        />
      </Col>
      <Col xs="auto">
        <Button type="submit" className='p-2 rounded'>Submit</Button>
      </Col>
    </Row>
  </Form>
  )
}

export default SearchComponent