// For Top Rated Products w high reviews

import React, { useEffect } from 'react'
import Carousel from 'react-multi-carousel';
import { Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import 'react-multi-carousel/lib/styles.css';

import Loader from './Loader';
import Message from './Message';
import { productTopRatedActions } from '../actions/productActions';
import Rating from './Rating';


function TopRatedCarousel() {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you, ok sure
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2
        }
    };

    const dispatch = useDispatch()
    const productTopRated = useSelector(state => state.productTopRated)
    const { loading, error, products } = productTopRated

    useEffect(() => {
        dispatch(productTopRatedActions())
    }, [dispatch])

    return (
        loading ? <Loader />
            : error ? <Message variant='danger'>{error}</Message>
                : (
                    <>
                        <h3>Featured Products:</h3>
                        <Carousel responsive={responsive}>
                            {products.map(product => (
                                <Card style={{ height: 'auto', margin: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)', borderRadius: '10px' }} rounded>
                                    <Link key={product.id} to={`/products/${product.id}/`}>
                                        <Card.Img variant='top' src={`https://res.cloudinary.com/depbmpoam/${product.image}`} alt={product.name} className='img-fluid' style={{ height: '275px', width: '350px' }} />
                                    </Link>
                                    <Card.Body>
                                        <Link to={`/products/${product.id}`}>
                                            <Card.Title>
                                                <strong>{product.name}</strong>
                                            </Card.Title>
                                        </Link>
                                        <Card.Text as='h3' className='mt-2'>
                                            $ {product.price}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            ))}
                        </Carousel>
                    </>
                )
    )
}

export default TopRatedCarousel