import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import ProductCard from '../components/ProductCard';
import Welcome from '../components/Welcome';
import { productListActions } from '../actions/productActions';
import TopRatedCarousel from '../components/TopRatedCarousel';
import Wristbands from '../components/Wristbands';
import Bracelets from '../components/Bracelets';
import Chockers from '../components/Chockers';
import Anklets from '../components/Anklets';
import Nametags from '../components/Nametags';



function HomeScreen() {
    const dispatch = useDispatch()
    const productList = useSelector(state => state.productList)
    const { loading, error, products } = productList
    const location = useLocation()

    const keyword = location.search

    useEffect(() => {
        dispatch(productListActions(keyword));
    }, [dispatch, keyword]);
    return (
        <>
            <Welcome />
            <div className='mt-1'>
                <div className='mt-2'>
                    <TopRatedCarousel />
                </div>
                {/*
                <div>
                    <h3>Featured Products</h3>
                    <ProductCard />
                </div>
                */}
                <div className='mt-3'>
                    <h2>Shop by Categories</h2>
                    <Wristbands />
                    <Bracelets />
                    <Chockers />
                    <Anklets />
                    <Nametags />
                </div>
            </div>
        </>
    )
}

export default HomeScreen