import React from 'react';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

function CheckoutSteps({ step1, step2, step3, step4 }) {
    return (
        <div>
            <Nav className='justify-content-center mb-4'>
                <Nav.Item className="mx-2">
                    {step1 ? (
                        <LinkContainer to='/login'>
                            <Nav.Link>Login</Nav.Link>
                        </LinkContainer>
                    ) : (
                        <Nav.Link disabled>Login</Nav.Link>
                    )}
                </Nav.Item>

                <Nav.Item className="mx-2">
                    {step2 ? (
                        <LinkContainer to='/checkout'>
                            <Nav.Link>Shipping</Nav.Link>
                        </LinkContainer>
                    ) : (
                        <Nav.Link disabled>Shipping</Nav.Link>
                    )}
                </Nav.Item>

                <Nav.Item className="mx-2">
                    {step3 ? (
                        <LinkContainer to='/payment'>
                            <Nav.Link>Payment</Nav.Link>
                        </LinkContainer>
                    ) : (
                        <Nav.Link disabled>Payment</Nav.Link>
                    )}
                </Nav.Item>

                <Nav.Item className="mx-2">
                    {step4 ? (
                        <LinkContainer to='/place-order'>
                            <Nav.Link>Place Order</Nav.Link>
                        </LinkContainer>
                    ) : (
                        <Nav.Link disabled>Place Order</Nav.Link>
                    )}
                </Nav.Item>
            </Nav>
        </div>
    );
}

export default CheckoutSteps;
