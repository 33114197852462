export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST'
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS'
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL'

export const ADD_COUPON_REQUEST = 'ADD_COUPON_REQUEST'
export const ADD_COUPON_SUCCESS = 'ADD_COUPON_SUCCESS'
export const ADD_COUPON_FAIL = 'ADD_COUPON_FAIL'

export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST'
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS'
export const ORDER_DETAILS_FAIL = 'ORDER_DETAILS_FAIL'

export const ORDER_PAY_REQUEST = 'ORDER_PAY_REQUEST'
export const ORDER_PAY_SUCCESS = 'ORDER_PAY_SUCCESS'
export const ORDER_PAY_FAIL = 'ORDER_PAY_FAIL'
export const ORDER_PAY_RESET = 'ORDER_PAY_RESET'

export const USER_ORDERS_REQUEST = "USER_ORDERS_REQUEST"
export const USER_ORDERS_SUCCESS = "USER_ORDERS_SUCCESS"
export const USER_ORDERS_FAIL = "USER_ORDERS_FAIL"
export const USER_ORDERS_RESET = "USER_ORDERS_RESET"

