import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Button } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast'

import { userRegisterActions } from '../actions/userActions';
import FormContainer from '../components/FormContainer';
import Message from '../components/Message';
import Loader from '../components/Loader';

function RegisterScreen() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');

    const location = useLocation();
    const redirect = location.search ? location.search.split('=')[1] : '/';
    const dispatch = useDispatch();
    const userRegister = useSelector(state => state.userRegister);
    const { loading, error, success } = userRegister;

    useEffect(() => {
        if (success) {
            setTimeout(() => {
                toast.success("User registered successfully, check your email to verify your account.")
            }, 2000);
        }
    }, [success]);

    const submitHandler = (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setMessage('Your passwords do not match!');
        } else {
            dispatch(userRegisterActions(name, email, password));
            if (success) {
                setName('')
                setEmail('')
                setPassword('')
                setConfirmPassword('')
            }
        }
    };

    return (
        <FormContainer>
            {loading && <Loader />}
            {error && <Message variant='danger'>{error}</Message>}
            {message && <Message variant='danger'>{message}</Message>}
            <div><Toaster/></div>
            <Form onSubmit={submitHandler}>
                <h3>Create an account</h3>

                <Form.Group controlId='name'>
                    <Form.Label>Name:</Form.Label>
                    <Form.Control
                        required
                        type='name'
                        value={name}
                        placeholder='Enter your name'
                        onChange={(e) => setName(e.target.value)}
                    ></Form.Control>
                </Form.Group>

                <Form.Group controlId='email' className='mt-2'>
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                        required
                        type='email'
                        value={email}
                        placeholder='Enter email'
                        onChange={(e) => setEmail(e.target.value)}
                    ></Form.Control>
                    <Form.Text className='text-muted'><i class="fa-solid fa-user-secret"></i> We will never share your email.</Form.Text>
                </Form.Group>

                <Form.Group controlId='password' className='mt-2'>
                    <Form.Label>Password:</Form.Label>
                    <Form.Control
                        required
                        type='password'
                        value={password}
                        placeholder='Enter password'
                        onChange={(e) => setPassword(e.target.value)}
                    ></Form.Control>
                    <Form.Text className='text-muted'><i class="fa-solid fa-lightbulb"></i> For a strong passowrd, consider using a mix of lowercase and uppercase letters, special characters and numbers</Form.Text>
                </Form.Group>

                <Form.Group controlId='confirmPassword' className='mt-2'>
                    <Form.Label>Confirm Password:</Form.Label>
                    <Form.Control
                        required
                        type='password'
                        value={confirmPassword}
                        placeholder='Confirm password'
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    ></Form.Control>
                </Form.Group>

                <Button type='submit' className='btn-custom rounded mt-2'>Create Account</Button>

                <Row className='mt-2'>
                    <Col>
                        Have an account? <Link to={redirect ? `/login?redirect=${redirect}` : '/login'}>Login Here.</Link>
                    </Col>
                </Row>
            </Form>
        </FormContainer>
    );
}

export default RegisterScreen;
