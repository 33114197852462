import React, { useState, useEffect } from 'react'
import { Form, Button, Row, Col, ListGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import toast from 'react-hot-toast';

import { productReviewActions } from '../actions/productActions'
import { PRODUCT_REVIEW_RESET } from '../constants/productConstants'
import Rating from './Rating'
import Message from './Message'
import Loader from './Loader'

function Review() {

    const { id } = useParams()
    const dispatch = useDispatch()

    const productDetails = useSelector(state => state.productDetails);
    const { product } = productDetails;

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const reviews = useSelector(state => state.productReview)
    const { loading: loadingReview, error: errorReview, success: successReview } = reviews

    const [comment, setComment] = useState('')
    const [rating, setRating] = useState(0)

    useEffect(() => {
        if (successReview) {
            setComment('')
            setRating(0)
            dispatch({ type: PRODUCT_REVIEW_RESET })
            toast.success("Review submitted")
        }
    }, [dispatch, successReview])

    const reviewHandler = (e) => {
        if (rating && comment) {
            e.preventDefault()
            dispatch(productReviewActions(id, { comment, rating }))
        }
    }

    return (
        <div className='mt-2'>
            <h4 className='text-center'>Reviews</h4>
            <Row>
                {/* Col 1, Review Form */}
                <Col md={6} className='mt-2'>
                    {errorReview && <Message variant='danger'>{errorReview}</Message>}

                    {!userInfo ? (<Message variant='info'>Please <Link to='/login'>log in</Link> to submit a review.</Message>) : (
                        <Form onSubmit={reviewHandler}>
                            <Form.Group className="mb-3" controlId="rating">
                                <Form.Label>Rating:</Form.Label>
                                <br />
                                <Form.Text className='text-muted'>*required field</Form.Text>
                                <Form.Control as='select' placeholder="Enter a rating between 0 and 5" value={rating} onChange={(e) => setRating(e.target.value)}>
                                    <option value=''>Select...</option>
                                    <option value='1'>1 - Poor</option>
                                    <option value='2'>2 - Fair</option>
                                    <option value='3'>3 - Good</option>
                                    <option value='4'>4 - Very Good</option>
                                    <option value='5'>5 - Excellent</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formTextArea">
                                <Form.Label>Comment:</Form.Label>
                                <br />
                                <Form.Text className='text-muted'>*required field</Form.Text>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                />
                            </Form.Group>

                            <Button variant="primary" type="submit" className='mt-2 rounded' disabled={loadingReview}>
                                Submit Review
                            </Button>
                        </Form>
                    )}

                </Col>

                {/* Col 2, Reviews */}
                <Col md={6}>
                    <h3>Product Reviews:</h3>

                    {product.reviews.length === 0 && <Message variant='info'>No Reviews Yet. Add one to start off!</Message>}

                    <ListGroup variant='flush'>
                        {product.reviews.map((review) => (
                            <ListGroup.Item key={review.id}>
                                <strong>{review.name}</strong>
                                <Rating value={review.rating} color={'#FFD700'} />
                                <p>Comment: {review.comment}</p>
                                <p>Created at: {review.createdAt.substring(0, 10)}</p>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                    <Link to={`/products/${id}/reviews`} className="mt-3 d-block">
                        See All {product.reviews.length} Reviews 
                    </Link>
                </Col>
            </Row>
        </div>
    )
}

export default Review