import React from 'react';

function Welcome() {
    const welcomeStyle = {
        //backgroundImage: 'url("https://res.cloudinary.com/depbmpoam/image/upload/v1731348567/download_1_a8mb4s.jpg")',
        backgroundColor: "#006243",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: '20px',
        color: 'white',  
        height: '625px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        backdropFilter: 'brightness(0.7)',
        filter: 'contrast(1.2)',
        position: 'relative',
    };

    const textStyle = {
        color: '#fff',
        //fontSize: '17px',
        //fontWeight: 'bold',
        fontWeight: '500', // Makes the text extra bold
        fontSize: '1rem', // Increase font size for emphasis
        letterSpacing: '0.8px', // Adds space between letters for readability
        lineHeight: '1.8', // Improves readability by increasing line spacing
        textShadow: '1.5px 1.5px 3px rgba(0, 0, 0, 0.5)', // Stronger shadow for contrast
        //maxWidth: '800px',
    };

    return (
        <div 
            className='welcome mb-1'
            style={welcomeStyle}
        >
            <h1 style={{ color: '#fff' }}>Welcome to Distrikt Arts</h1>
            <p>
                Jambo! Welcome to Distrikt Arts, your gateway to the vibrant and colorful world of African beadwork! We specialize in bringing the finest handcrafted beaded products from the heart of Africa straight to your doorstep in the US. Our collection features stunning jewelry like wrist bands, earrings, home decor, and accessories, all made with love and skill by talented artisans. At Distrikt Arts, we are passionate about sharing the rich cultural heritage of Africa while supporting local communities. Join us in celebrating craftsmanship and adding a touch of African beauty to your life. Karibu, that is, Welcome to Distrikt Arts!
            </p>
            <p>Shop with us now, as we bring Africa to you</p>
            <i className="fa-solid fa-arrow-down"></i>
        </div>
    );
}

export default Welcome;
