import React, { useState, useEffect } from 'react';
import { Col, Row, Image, Button, ListGroup, Card, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';


import { orderCreateActions, validateCoupon } from '../actions/orderActions';

import Loader from '../components/Loader'
// import { CouponLoader } from '../components/Loader';
import CheckoutSteps from '../components/CheckoutSteps';
import Message from '../components/Message';


function PlaceOrderScreen() {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [taxRate, setTaxRate] = useState(0.06)

    const user = useSelector(state => state.userLogin)
    const { userInfo } = user

    const orderItems = useSelector(state => state.orderCreate)
    const { loading, error, success, order } = orderItems

    const cart = useSelector(state => state.cart)
    const { shippingAddress, paymentMethod, cartItems } = cart


    const [coupon, setCoupon] = useState('')

    useEffect(() => {
        const config = {
            headers: {
                "Content-type": "application/json"
            }
        }

        if (shippingAddress && shippingAddress.state) {
            axios
                .get(`${process.env.REACT_APP_TAX_API_URL}/taxrates?state=${shippingAddress.state}`, config)
                .then(response => {
                    const {state_rate} = response.data['state_rate'] || {}
                    if (state_rate) {
                        setTaxRate(state_rate / 100)
                    }
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }, [])

    cart.itemsPrice = cartItems.reduce((acc, item) => acc + item.qty * item.price, 0).toFixed(2)
    cart.shippingPrice = Number(cartItems.itemsPrice > 200 ? 0 : 20).toFixed(2)
    cart.taxPrice = (taxRate * cart.itemsPrice).toFixed(2);
    cart.totalPrice = (Number(cart.itemsPrice) + Number(cart.taxPrice) + Number(cart.shippingPrice)).toFixed(2)

    useEffect(() => {
        if (success && order) {
            navigate(`/order/${order.id}`)
        }

    }, [success, order, navigate])

    if (!paymentMethod) {
        navigate("/payment")
    }

    const placeOrder = () => {
        if (!userInfo) {
            toast.error("You need to log in first.")
            navigate('/login')
        } else {
            dispatch(orderCreateActions(
                {
                    orderItems: cartItems,
                    shippingAddress: shippingAddress,
                    paymentMethod: paymentMethod,
                    shippingPrice: cart.shippingPrice,
                    taxPrice: cart.taxPrice,
                    itemsPrice: cart.itemsPrice,
                    totalPrice: cart.totalPrice,
                }
            ))
            toast.success("Order created successfully!")
        }
    }

    const validateCoupon = () => {
        dispatch(validateCoupon(coupon))
    }

    return (
        <div>
            <CheckoutSteps step1 step2 step3 step4 />
            <div>
                <Toaster
                    toastOptions={{
                        className: '',
                        duration: 5000,
                        style: {
                            background: '#363636',
                            color: '#fff',
                        },

                        success: {
                            duration: 4000,
                            theme: {
                                primary: 'green',
                                secondary: 'black',
                            },
                        },
                    }}
                />
            </div>
            <Row>
                <Col md={7}>
                    <ListGroup variant='flush'>
                        <ListGroup.Item>
                            <h3>Shipping Information:</h3>
                            <strong>Receipient's Name: {shippingAddress.name}</strong>
                            <br />
                            <strong>{shippingAddress.address}, {shippingAddress.city}, {shippingAddress.postalCode}, {shippingAddress.country}</strong>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <h3>Payment Information:</h3>
                            <strong>Payment Method: {paymentMethod}</strong>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <h3>Order Items:</h3>
                            {cartItems.length === 0 ? <Message variant='info'>
                                Your cart is empty.<Link to='/'>Go back to Products</Link>
                            </Message> : (
                                <ListGroup variant='flush'>
                                    {cartItems.map((item, index) => (
                                        <ListGroup.Item key={index}>
                                            <Row>
                                                <Col md={1}>
                                                    <Image src={`https://res.cloudinary.com/depbmpoam/${item.image}`} alt={item.name} style={{ height: "50px", width: "50px" }} fluid rounded />
                                                </Col>
                                                <Col md={4}>
                                                    <Link to={`/products/${item.product}`}>{item.name}</Link>
                                                </Col>
                                                <Col md={3}>
                                                    Total per item: {item.qty} X ${item.price} = ${(item.qty * item.price).toFixed(2)}
                                                </Col>
                                                <Col md={2}>
                                                    Size: {item.size}
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            )}
                        </ListGroup.Item>
                    </ListGroup>

                </Col>

                <Col md={5}>
                    <Card className='mt-2 rounded'>
                        <ListGroup>
                            <ListGroup.Item>
                                <h3>Order Summary:</h3>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                Item Price: $ {cart.itemsPrice}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                Shipping Cost: $ {cart.shippingPrice}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                Tax: $ {cart.taxPrice}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                Total Order Price: $ {cart.totalPrice}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Form>
                                    <Form.Group>
                                        <Form.Label><strong>Got a coupon?</strong></Form.Label>
                                        <Form.Control type='text' placeholder='Enter Coupon' value={coupon} onChange={(e) => setCoupon(e.target.value)} />
                                        <Col md={8}><Button className='btn btn-block' onClick={validateCoupon}>Apply Coupon</Button></Col>
                                    </Form.Group>
                                </Form>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                Final Order Price: $ {cart.totalPrice}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                {loading && <Loader />}
                                {error && <Message variant='danger'>{error}</Message>}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Button type='button' className='btn-block' variant='primary' onClick={placeOrder}>Place Order</Button>
                            </ListGroup.Item>
                        </ListGroup>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default PlaceOrderScreen

