import { PRODUCT_LIST_FAIL, PRODUCT_LIST_REQUEST, PRODUCT_LIST_SUCCESS, PRODUCT_DETAILS_FAIL, PRODUCT_DETAILS_REQUEST, PRODUCT_DETAILS_SUCCESS, PRODUCT_REVIEW_REQUEST, PRODUCT_REVIEW_SUCCESS, PRODUCT_REVIEW_FAIL, PRODUCT_REVIEW_RESET, TOP_PRODUCTS_REQUEST, TOP_PRODUCTS_SUCCESS, TOP_PRODUCTS_FAIL, GET_PRODUCT_REVIEWS_REQUEST, GET_PRODUCT_REVIEWS_SUCCESS, GET_PRODUCT_REVIEWS_FAIL } from "../constants/productConstants"

export const productListReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { loading: true, products: [] }

    case PRODUCT_LIST_SUCCESS:
      return { loading: false, products: action.payload }

    case PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload }

    default:
      return state
  }
}

export const productDetailsReducer = (state = { product: { reviews: [] } }, action) => {
  switch (action.type) {
    case PRODUCT_DETAILS_REQUEST:
      return { loading: true, ...state };

    case PRODUCT_DETAILS_SUCCESS:
      return { loading: false, product: action.payload };

    case PRODUCT_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const productReviewReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_REVIEW_REQUEST:
      return { loading: true, ...state }

    case PRODUCT_REVIEW_SUCCESS:
      return { loading: false, success: true }

    case PRODUCT_REVIEW_FAIL:
      return { loading: false, error: action.payload }

    case PRODUCT_REVIEW_RESET:
      return {}

    default:
      return state
  }
}

export const productTopRatedReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case TOP_PRODUCTS_REQUEST:
      return { loading: true, products: [] }

    case TOP_PRODUCTS_SUCCESS:
      return { loading: false, products: action.payload }

    case TOP_PRODUCTS_FAIL:
      return { loading: false, error: action.payload }

    default:
      return state
  }
}

export const productReviewsReducer = (state = { reviews: [] }, action) => {
  switch (action.type) {
    case GET_PRODUCT_REVIEWS_REQUEST:
      return { loading: true }

    case GET_PRODUCT_REVIEWS_SUCCESS:
      return { loading: false, reviews: action.payload }

    case GET_PRODUCT_REVIEWS_FAIL:
      return { loading:false, error: action.payload }

    default:
      return state
  }
}
