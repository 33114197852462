import axios from "axios";
import { USER_LOGIN_REQUEST, USER_LOGIN_FAIL, USER_LOGIN_SUCCESS, USER_LOGOUT, USER_REGISTER_FAIL, USER_REGISTER_REQUEST, USER_REGISTER_SUCCESS, USER_DETAILS_FAIL, USER_DETAILS_REQUEST, USER_DETAILS_SUCCESS, USER_UPDATE_FAIL, USER_UPDATE_REQUEST, USER_UPDATE_SUCCESS, USER_DETAILS_RESET, USER_VERIFY_REQUEST, USER_VERIFY_FAIL, USER_VERIFY_SUCCESS, RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAIL, SEND_RESET_EMAIL_REQUEST, SEND_RESET_EMAIL_SUCCESS, SEND_RESET_EMAIL_FAIL, VERIFY_EMAIL_REQUEST, VERIFY_EMAIL_SUCCESS, VERIFY_EMAIL_FAIL, CONTACT_EMAIL_REQUEST, CONTACT_EMAIL_SUCCESS, CONTACT_EMAIL_FAIL, RESEND_OTP_REQUEST, RESEND_OTP_SUCCESS, RESEND_OTP_FAIL } from "../constants/userConstants";
import { USER_ORDERS_RESET } from "../constants/orderConstants";


export const userLoginActions = (email, password) => async (dispatch) => {
    try {
        dispatch({
            type: USER_LOGIN_REQUEST
        })

        const body = {
            "username": email,
            "password": password
        }

        const config = {
            headers: {
                'Content-type': "application/json",
            }
        }

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/users/login/`, body, config)

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data
        })

        localStorage.setItem("userInfo", JSON.stringify(data))

    } catch (error) {
        dispatch({
            type: USER_LOGIN_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const logout = () => (dispatch) => {

    localStorage.removeItem("userInfo")

    dispatch({
        type: USER_LOGOUT
    })

    dispatch({
        type: USER_DETAILS_RESET
    })

    dispatch({
        type: USER_ORDERS_RESET
    })
}

export const userRegisterActions = (name, email, password) => async (dispatch) => {
    try {
        dispatch({
            type: USER_REGISTER_REQUEST
        });

        const body = {
            name: name,
            email: email,
            password: password
        };

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/users/register/`, body, config);

        dispatch({
            type: USER_REGISTER_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: USER_REGISTER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
};

export const userDetailsActions = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_DETAILS_REQUEST
        })

        const { userLogin: { userInfo } } = getState()

        if (!userInfo || !userInfo.token) {
            dispatch(logout())
            return
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        };

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/users/${id}/`, config)

        dispatch({
            type: USER_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: USER_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const updateActions = (name, email) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_UPDATE_REQUEST
        })

        const { userLogin: { userInfo } } = getState()

        const body = {
            "name": name,
            "email": email
        }

        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(`${process.env.REACT_APP_API_URL}/users/profile/update/`, body, config)

        dispatch({
            type: USER_UPDATE_SUCCESS,
            payload: data
        })

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: USER_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const verifyEmail = (token, email) => async (dispatch) => {
    try {
        dispatch({
            type: USER_VERIFY_REQUEST
        });

        const config = {
            headers: {
                "Content-type": "application/json"
            }
        };

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}/users/verify-email?token=${token}&email=${email}`,
            config
        );

        dispatch({
            type: USER_VERIFY_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: USER_VERIFY_FAIL,
            payload:
                error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message,
        });
    }
};

// Send email action

export const sendResetEmailAction = (email) => async (dispatch) => {
    try {
        dispatch({ type: SEND_RESET_EMAIL_REQUEST })

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/users/send-reset-email/`, { email })

        dispatch({
            type: SEND_RESET_EMAIL_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: SEND_RESET_EMAIL_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}


export const verifyResetOtp = (otp) => async (dispatch) => {
    try {
        dispatch({
            type: VERIFY_EMAIL_REQUEST
        })

        const body = {
            "otp": otp
        }

        const config = {
            headers: {
                "Content-type": "application/json"
            }
        }

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/users/verify-reset-otp/`, body, config)

        dispatch({
            type: VERIFY_EMAIL_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: VERIFY_EMAIL_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const resetPasswordAction = ({uid, token, password}) => async (dispatch) => {
    try {
        dispatch({
            type: RESET_PASSWORD_REQUEST
        })

        const body = {
            'uid': uid,
            'token': token,
            "password": password
        }

        const config = {
            headers: {
                "Content-type": "application/json"
            }
        }

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/users/reset-password/`, body, config)

        dispatch({
            type: RESET_PASSWORD_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: RESET_PASSWORD_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const sendContactEmailAction = (name, email, message) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CONTACT_EMAIL_REQUEST
        })

        const { userLogin: { userInfo } } = getState()

        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const body = {
            "name": name,
            "email": email,
            "message": message
        }

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/users/contact-us/`, body, config)

        dispatch({
            type: CONTACT_EMAIL_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: CONTACT_EMAIL_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const resendOtpAction = (email) => async (dispatch) => {
    try {
        dispatch({
            type: RESEND_OTP_REQUEST
        })
    
        const body = {
            "email": email
        }
    
        const config = {
            headers : {
                "Content-type": "application/json"
            }
        }
    
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/users/resend-otp/`, body, config)
    
        dispatch({
            type: RESEND_OTP_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: RESEND_OTP_FAIL,
            payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
        })
    }
}