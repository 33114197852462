export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST'
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS'
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL'

export const PRODUCT_DETAILS_REQUEST = 'PRODUCT_DETAILS_REQUEST'
export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS'
export const PRODUCT_DETAILS_FAIL = 'PRODUCT_DETAILS_FAIL'

export const PRODUCT_REVIEW_REQUEST = "PRODUCT_REVIEW_REQUEST"
export const PRODUCT_REVIEW_SUCCESS = "PRODUCT_REVIEW_SUCCESS"
export const PRODUCT_REVIEW_FAIL = "PRODUCT_REVIEW_FAIL"
export const PRODUCT_REVIEW_RESET = "PRODUCT_REVIEW_RESET"

export const TOP_PRODUCTS_REQUEST = 'TOP_PRODUCTS_REQUEST'
export const TOP_PRODUCTS_SUCCESS = 'TOP_PRODUCTS_SUCCESS'
export const TOP_PRODUCTS_FAIL = 'TOP_PRODUCTS_FAIL'

export const GET_PRODUCT_REVIEWS_REQUEST = 'GET_PRODUCT_REVIEWS_REQUEST'
export const GET_PRODUCT_REVIEWS_SUCCESS = 'GET_PRODUCT_REVIEWS_SUCCESS'
export const GET_PRODUCT_REVIEWS_FAIL = 'GET_PRODUCT_REVIEWS_FAIL'
