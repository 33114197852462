import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';


import { verifyEmail } from '../actions/userActions';

const VerifyScreen = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const email = searchParams.get('email');
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userVerify = useSelector((state) => state.userVerify);
    const { success, error } = userVerify;

    useEffect(() => {
        if (token && email) {
            dispatch(verifyEmail(token, email));
        }
    }, [dispatch, token, email]);

    useEffect(() => {
        if (success) {
            navigate('/login');
        }
    }, [success, navigate]);

    return (
        <div>
            <h1>Email Verification</h1>
            {error ? <p>Error: {error}</p> : <p>Verifying your email...</p>}
            {success && <h6>Email verified successfully! You can now log in</h6>}
        </div>
    );
};

export default VerifyScreen;
