import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";
import HomeScreen from "./screens/HomeScreen";
import AboutScreen from "./screens/AboutScreen";
import ContactScreen from "./screens/ContactScreen";
import ProductScreen from "./screens/ProductScreen";
import CartScreen from "./screens/CartScreen";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import ProfileScreen from "./screens/ProfileScreen";
import VerifyScreen from "./screens/VerifyScreen";
import CheckoutScreen from "./screens/CheckoutScreen";
import PaymentScreen from "./screens/PaymentScreen";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import OrderScreen from "./screens/OrderScreen";
import SendResetEmailScreen from "./screens/SendResetEmailScreen";
import VerifyResetScreen from "./screens/VerifyResetScreen";
import ResetPasswordScreen from "./screens/ResetPasswordScreen";
import ReviewsScreen from "./screens/ReviewsScreen";

function App() {
  return (
    <Router>
      <Header />
      <main className="py-1">
        <Routes>
          <Route path="/" element={<HomeScreen />} exact />
          <Route path="/products/:id" element={<ProductScreen />} />
          <Route path="/order/:id" element={<OrderScreen />} />
          <Route path="/cart/:id?" element={<CartScreen />} />
          <Route path='/about' element={<AboutScreen />} />
          <Route path="/contact-us" element={<ContactScreen />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/register" element={<RegisterScreen />} />
          <Route path="/profile" element={<ProfileScreen />} />
          <Route path="/verify-email" element={<VerifyScreen />} />
          <Route path="/checkout" element={<CheckoutScreen />} />
          <Route path="/payment" element={<PaymentScreen />} />
          <Route path="/place-order" element={<PlaceOrderScreen />} />
          <Route path="/send-reset-email" element={<SendResetEmailScreen />} />
          <Route path="/verify-reset-otp" element={<VerifyResetScreen />} />
          <Route path="/reset-password/:uid/:token" element={<ResetPasswordScreen />} />
          <Route path="/products/:id/reviews" element={<ReviewsScreen />} />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
